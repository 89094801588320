::-ms-reveal {
  display: none;
}

.sidebar-link {
  text-decoration: none;
  width: 100%;
}

.sidebar-link-active {
  background: rgba(255, 255, 255, 0.08);
  border-radius: 8px;

  .MuiListItemIcon-root {
    filter: brightness(0) saturate(100%) invert(93%) sepia(44%) saturate(3860%)
      hue-rotate(78deg) brightness(97%) contrast(80%);
  }

  .MuiTypography-root {
    color: #21e09d !important;
  }
}

.MuiChip-root {
  &.MuiChip-outlinedSuccess {
    background-color: #e1fbf2;
    border: 1px solid #bfded3;
    padding-bottom: 2px;

    .MuiChip-label {
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      color: #0f875b;
    }

    &.Mui-disabled {
      background-color: rgba(0, 0, 0, 0.16);

      .MuiChip-label {
        color: #444444;
      }
    }
  }

  &.MuiChip-colorError {
    .MuiChip-label {
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      color: #f16149;
    }

    &.MuiChip-outlinedError {
      background-color: #ffe7e3;
    }
  }
}

.members-count {
  display: flex;
  flex-direction: row;
  gap: 4px;
  font-weight: 500;
  align-items: center;
}

.MuiButton-textPrimary {
  height: 40px !important;
  background-color: rgba(21, 96, 212, 0.08) !important;
  border-radius: 8px !important;
  border: 1px solid rgba(21, 96, 212, 0.12) !important;

  &:hover {
    background-color: rgba(21, 96, 212, 0.18) !important;
  }
}

.expanded-icon {
  transform: rotate(90deg);
}

.action-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 20px;
  transform-origin: 0% 50%;

  img {
    max-width: 20px;
    max-height: 20px;
    cursor: pointer;

    &:hover {
      background-color: #e6e6e6;
      border-radius: 50%;
      outline: 5px solid #e6e6e6;
    }

    &.disabled-img {
      filter: invert(85%) sepia(0%) saturate(521%) hue-rotate(137deg)
        brightness(96%) contrast(97%);

      &:hover {
        background-color: transparent;
        border-radius: 50%;
        outline: none;
      }
    }
  }
}

.img-disabled {
  opacity: 0.3;
}

.custom-checkbox-root {
  .MuiSvgIcon-root {
    width: 18px;
    height: 18px;
  }
}

.MuiTablePagination-actions {
  &:not(.Mui-disabled) {
    .MuiSvgIcon-root {
      &:hover {
        background-color: #e6e6e6;
        border-radius: 50%;
      }
    }
  }
}

//Loader
.custom-loader-wrapper {
  display: flex;
  position: relative;
  justify-content: center;

  .custom-loader {
    width: 48px;
    height: 48px;
    border: 5px solid #e6e6e6;
    border-bottom-color: #0157de;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}

//toastify
.toastify {
  border-radius: 8px !important;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  min-width: 350px;
  margin-left: auto;
  padding-right: 34px;
  display: flex !important;
  align-items: center;

  .toastify-avatar {
    filter: invert(100%) sepia(0%) saturate(580%) hue-rotate(186deg)
      brightness(106%) contrast(101%);
    margin: 0px 8px 0 0;
    height: 22px;
    width: 22px;
  }

  .toast-close {
    position: absolute;
    right: 12px;
    top: 12px;
  }
}

.table-taxt-and-icon-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding-left: 5px;
  width: max-content;
  cursor: pointer;

  .link-text {
    // font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    text-decoration: underline;
  }

  .link-icon-wrap {
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ededed;
    border-radius: 50%;

    img {
      max-height: 16px;
      max-width: 16px;
    }
  }

  &:hover {
    background-color: rgba(21, 96, 212, 0.08);
    border-radius: 32px;

    .link-text {
      color: #1560d4;
    }

    .link-icon-wrap {
      background-color: transparent;

      img {
        filter: invert(21%) sepia(79%) saturate(4776%) hue-rotate(212deg)
          brightness(94%) contrast(83%);
      }
    }
  }

  &.cursor {
    cursor: pointer;
  }
}

.table-taxt-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 2px 7px 5px;
  width: max-content;
  cursor: pointer;

  .link-text {
    // font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    text-decoration: underline;
  }

  &:hover {
    background-color: rgba(21, 96, 212, 0.08);
    border-radius: 32px;

    .link-text {
      color: #1560d4;
    }
  }

  &.cursor {
    cursor: pointer;
  }
}

.no-underline {
  text-decoration: none;
}

.table-paper-container {
  width: "100%";
  margin-bottom: 16px;
  border-radius: 16px;
  border: 1px solid #e6e6e6;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
  overflow: "hidden";
}

.avater-img {
  max-width: 19px;
  max-height: 19px;
}

.details-avater-img {
  max-width: 38px;
  max-height: 38px;
}

//quill editor

.quill {
  .ql-toolbar {
    border: 1px solid #cccccc;
    border-radius: 8px 8px 0px 0px;
  }

  .ql-container {
    border-radius: 0px 0px 8px 8px;
    min-height: 175px;
  }
}

//sweatAlert
.swal2-container {
  z-index: 1160 !important;

  .swal2-popup {
    max-width: 326px;
    border-radius: 8px;

    .swal2-actions {
      margin-top: 0;
      padding: 0;

      .swal2-confirm {
        background: #0b57d0 !important;
        border-radius: 8px;
        font-weight: normal;
        text-transform: uppercase;
        // font-family: $title-font;
        font-size: 14px;
        line-height: 18px;
        color: #ffffff !important;

        &:focus {
          box-shadow: 0 0 0 3px rgb(11 87 208 / 50%);
        }
      }

      .swal2-cancel {
        margin-right: 12px;
        cursor: pointer;
        font-size: 14px;
        line-height: 18px;
        text-transform: uppercase;
        border: 1px solid #cccccc;

        padding: 0 16px;
        display: flex;
        align-items: center;
        color: #808080;
        height: 36px;
        background-color: #fff;
        border-radius: 8px;
        transition: all 0.3s ease;

        &:hover {
          opacity: 0.8;
          color: #666666f4;
        }
      }
    }

    .swal2-content,
    .swal2-header {
      padding: 0;
    }

    .swal2-title {
      font-weight: 400 !important;
      font-size: 18px !important;
      line-height: 24px !important;
      color: #000000;
    }

    .swal2-html-container {
      font-weight: normal;
      font-size: 13px;
      line-height: 18px;
      // color: $gray-40;
      margin-bottom: 10px;
    }
  }
}

.week-day-picker {
  display: flex;
  border: 1px solid #cccccc;
  border-radius: 8px;
  width: 100%;

  .picker {
    font-weight: 400;
    line-height: 19px;
    color: #636363;
    width: calc(100% - 58px);
    height: 42px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    &:not(:last-child) {
      border-right: 1px solid #cccccc;
    }

    &:hover {
      cursor: pointer;
    }

    &.selected {
      color: #ffffff;
      background-color: #1560d4;

      &:first-child {
        border-top-left-radius: 7px;
        border-bottom-left-radius: 7px;
      }

      &:last-child {
        border-top-right-radius: 7px;
        border-bottom-right-radius: 7px;
      }
    }
  }
}

//React date range picker

.calendarWrap {
  display: inline-block;
  position: relative;
  .calendarElement {
    position: absolute;
    left: 50%;
    transform: translateX(-88%);
    top: 40px;
    border: none;
    z-index: 999;
    box-shadow: 0px 1px 45px rgb(0 0 0 / 16%);
    border-radius: 8px;

    .rdrCalendarWrapper{
      border-radius: 8px;
      .rdrMonthAndYearWrapper{
        padding-top: 0;
      }
    }
  }
}
