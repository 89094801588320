//Components

//Pages
@import "./general";

//custom scrollbar
::-webkit-scrollbar {
  width: 7px;
  height: 5px; /* width of the entire scrollbar */
}

::-webkit-scrollbar-track {
  background: #e6e6e6; /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
  background-color: #cccccc; /* color of the scroll thumb */
  border-radius: 20px;
}
